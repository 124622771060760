import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Compressor from "compressorjs";
import Swal from "sweetalert2";

import appointmentService from "~/utils/api/v1/appointmentService";
import { deleteImage } from "~/utils/api/v2/appointmentPatient";
import { AppointmentPatientV2, Image } from "~/utils/interfaces/AppointmentPatient";

interface UploadImageProps {
  appointmentId: string;
  appointmentPatient: AppointmentPatientV2;
  hasImage: boolean;
  setHasImage: (hasImage: boolean) => void;
}

const UploadAndDisplayImage = (props: UploadImageProps) => {
  const [selectedImage, setSelectedImage] = useState<Blob | null>(null);
  const [loading, setLoading] = useState(false);
  const [successfulUpload, setSuccessfulUpload] = useState(false);
  const [newImageURL, setNewImageURL] = useState<string>("");
  const { appointmentPatient } = props;
  const [pictures, setPictures] = useState<Image[]>([]);

  useEffect(() => {
    if (appointmentPatient.pictures.length > 0) {
      setSuccessfulUpload(true);
      setPictures(appointmentPatient.pictures);
      props.setHasImage(true);
    }
  }, [appointmentPatient.pictures]);

  useEffect(() => {
    if (selectedImage) {
      setNewImageURL(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const uploadDocumentPic = async (): Promise<void> => {
    setLoading(true);
    const formData = new FormData();
    if (appointmentPatient.id) {
      formData.append("appointment_patient_id", appointmentPatient.id);
    }
    if (selectedImage) {
      formData.append("files", selectedImage);
    }

    const uploaded = await appointmentService.uploadRegistryImage(props.appointmentId, formData);

    if (uploaded) {
      setSuccessfulUpload(true);
      props.setHasImage(true);
      setPictures([...pictures, {url: uploaded.data.url, id: uploaded.data.id}]);
      setNewImageURL("");
      Swal.fire({
        icon: "success",
        title: "Foto registrada",
      });
    } else {
      setSuccessfulUpload(false);
      Swal.fire({
        icon: "error",
        title: "La foto no se pudo subir",
      });
    }
    setLoading(false);
  };

  const changeFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];

    if (!file) {
      Swal.fire({
        icon: "error",
        title: "Por favor selecciona una imagen antes de intentar subirla",
      });

      return;
    }

    if (file.size > 1_000_000) {
      new Compressor(file, {
        quality: 0.2,
        success(result: File) {
          const compressedFile = new File([result], result.name);
          setSelectedImage(compressedFile);
          props.setHasImage(false);
          setSuccessfulUpload(false);
        },
        error(err) {
          console.error(err);
          Swal.fire({
            icon: "error",
            title: "Hubo problemas con la imagen",
          });
        },
      });
    } else {
      setSelectedImage(file);
      props.setHasImage(false);
      setSuccessfulUpload(false);
    }
  };

  const handleDeleteImage = async (id: string) => {
    Swal.fire({
      title: "¿Estás seguro que quieres eliminar esta foto?",
      text: "Esta acción no se puede deshacer",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deleteImage({appointmentPatientId: appointmentPatient.id, imageId: id});
        if (res.status === 204) {
          Swal.fire({
            icon: "success",
            title: "Foto eliminada",
          });
          setPictures(pictures.filter(picture => picture.id !== id));
        }
      }
    });
  };

  return (
    <div>
      {(selectedImage || appointmentPatient.picture) && (
        <div>
          <div className="flex flex-col gap-1">
            {pictures.map((picture, index) => (
              <div
                className="flex items-center justify-center w-fit relative"
                key={picture.id}
              >
                <div
                  className="absolute -top-2 -right-2 p-1 bg-red-500 rounded-full cursor-pointer hover:bg-red-600 transition-colors z-10"
                  onClick={() => handleDeleteImage(picture.id)}
                >
                  <AiOutlineClose className="text-white" size={16} />
                </div>
                <img
                  style={{ cursor: "pointer", marginRight: "auto" }}
                  src={picture.url}
                  alt="action register"
                  height="150px"
                  onClick={() => window.open(picture.url, "_blank")}
                  title="Click para ver la imagen completa"
                />
              </div>
            ))}
            {newImageURL && (
              <div className="p-2 bg-green-200 rounded max-w-fit border-zinc-500 border-[1px]">
                <p>Imagen a subir</p>
                <img
                  style={{ cursor: "pointer", marginRight: "auto" }}
                  src={newImageURL}
                  alt="action register"
                  height="150px"
                  onClick={() => window.open(newImageURL, "_blank")}
                  title="Click para ver la imagen completa"
                />
              </div>
            )}
            {!successfulUpload && (
              <Button
                color="secondary"
                variant="contained"
                disabled={loading}
                onClick={uploadDocumentPic}
              >
                {loading ? "Cargando..." : "Subir Foto"}
              </Button>
            )}
          </div>
        </div>
      )}
      <Grid
        container
        spacing={2}
        className="mt-1"
      >
        <Grid
          item
          xs={12}
        >
          <InputLabel htmlFor={`upload-photo-${appointmentPatient.id}`}>
            <Button
              color="primary"
              variant="contained"
              component="span"
            >
              {selectedImage || appointmentPatient.picture ? "Tomar otra foto" : "Tomar foto"}
            </Button>

            <Input
              type="file"
              id={`upload-photo-${appointmentPatient.id}`}
              name="upload-photo"
              style={{ display: "none" }}
              disabled={loading}
              inputProps={{ accept: "image/*" }}
              onChange={changeFile}
            />
          </InputLabel>
        </Grid>
      </Grid>
    </div>
  );
};

export default UploadAndDisplayImage;
