import React, { useEffect, useMemo, useState } from "react";

import { Button, Card, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { AxiosError } from "axios";
import { format } from "rut.js";
import Swal from "sweetalert2";

import clsx from "clsx";
import appointmentService from "~/utils/api/v1/appointmentService";
import { SupportedCountry } from "~/utils/constants/places";
import { Patient } from "~/utils/interfaces/AppointmentPatient";
import { onlyNumbers } from "~/utils/numbers";
import { validateEmail } from "~/utils/regex";
import { Checkbox } from "antd";

const initialPatientData: Patient = {
  id: "",
  email: "",
  first_name: "",
  second_name: "",
  last_name: "",
  second_last_name: "",
  phone: "",
  document_type: "",
  document_number: "",
  date_of_birth: "",
  gender: "male",
  country: "cl",
  full_name: "",
}

type NewPatientProps = {
  appointmentId: string;
  updateAppointmentData: () => void;
  setReloadOperativeData: (value: boolean) => void;
  setError: React.Dispatch<React.SetStateAction<AxiosError<any> | undefined>>
  country: SupportedCountry | undefined;
  salesSource: string;
  className?: string;
};

function NewPatient({
  appointmentId,
  updateAppointmentData,
  setReloadOperativeData,
  setError,
  country,
  salesSource,
  className,
}: NewPatientProps): JSX.Element {
  const [searched, setSearched] = useState<boolean>(false);
  const [found, setFound] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [patientData, setPatientData] = useState<Patient>(initialPatientData);
  const [loading, setLoading] = useState<boolean>(false);
  const [attended, setAttended] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const isFluOperative = salesSource === "operativos_influenza_2025"
  const isMexico = country === "México"

  const error = useMemo(() => emailError, [emailError]);

  const fetchPatient = async (): Promise<void> => {
    try {
      const res = await appointmentService.searchPatient(
        patientData.document_type,
        patientData.document_number,
        country === "México",
        isFluOperative
      );
      if (res) {
        if (res.data.data.length > 0) {
          setPatientData(res.data.data[0]);
          setFound(true);
        }
      }
      setSearched(true);
    } catch (err) {
      setError(err);
    }
  };

  const createPatient = async (): Promise<void> => {
    if (error) return;
    setLoading(true);
    try {
      const payload = { ...patientData };
      delete payload.id;
      delete payload.country;

      const birthDate: Date = new Date(payload.date_of_birth);
      birthDate.setHours(12);
      birthDate.setDate(birthDate.getDate() + 1);
      payload.date_of_birth = birthDate.toISOString();

      if (country && ["México", "Mexico"].includes(country)) {
        payload.document_type = "generic_id";
      }

      await appointmentService.createPatient(appointmentId, payload, attended, isFluOperative);
      await Swal.fire({
        title: "¡Hecho!",
      });

      updateAppointmentData();
      setReloadOperativeData(true);
      setFound(false);
      setSearched(false);
      setPatientData(initialPatientData);
      setLoading(false);
    } catch (err) {
      const errorMessages = err.response?.data?.email?.join(', ') || 'No se pudo crear el paciente';
      await Swal.fire({
        title: "Error",
        text: errorMessages,
        icon: "error",
      });
      setError(err as AxiosError);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (country === "México") {
      const parsedBirthDate = patientData.date_of_birth?.split("-").join("");
      patientData.document_number = `${patientData.first_name}${patientData.last_name}${parsedBirthDate}`.toUpperCase();
      if (!!patientData.first_name && !!patientData.last_name && !!patientData.date_of_birth) {
        fetchPatient();
      }
    }
  }, [patientData.first_name, patientData.last_name, patientData.date_of_birth]);

  const addPatient = async (): Promise<void> => {
    setLoading(true);
    try {
      const res = await appointmentService.addPatient(appointmentId, patientData.id ? patientData.id : "", attended);
      await Swal.fire({
        title: "¡Hecho!",
      });
      updateAppointmentData();
      setFound(false);
      setSearched(false);
      setPatientData(initialPatientData);
      setReloadOperativeData(true);
      setLoading(false);
    } catch (err) {
      const errorMessage = err.response?.data?.message || "No se pudo conectar el paciente";
      await Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
      });
      setLoading(false);
    }
  };

  const handleEmailBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (isFluOperative) return;
    const email = event.target.value;
    setEmailError(!validateEmail(email));
  };

  const validate = (attribute: string, newValue: string) => {
    if (isFluOperative) {
      setIsValid(
        !!patientData.first_name &&
        !!patientData.last_name &&
        !!patientData.date_of_birth &&
        !!patientData.gender &&
        !!patientData.document_type &&
        !!patientData.document_number
      )
      return;
    };
    setIsValid(
      !!patientData.first_name &&
      !!patientData.last_name &&
      !!patientData.date_of_birth &&
      !!patientData.gender &&
      (!!patientData.document_type || isMexico) &&
      (!!patientData.document_number || isMexico) &&
      !!patientData.email &&
      !!patientData.phone
    )
    if (attribute === 'email') {
      setEmailError(!validateEmail(newValue));
    }
  };

  const formChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    const attribute = event.target.id;
    validate(attribute, newValue);
    setPatientData((prevState: Patient): Patient => {
      let extraProps = {};
      if (prevState.document_type === "ci" && attribute === "document_number") {
        const ci = format(newValue);
        extraProps = {
          document_number: ci,
        };
      }
      if (attribute === "document_type" && newValue === "ci") {
        const ci = format(onlyNumbers(prevState.document_number ? prevState.document_number : ""));
        extraProps = {
          document_number: ci,
        };
      }
      return {
        ...prevState,
        [attribute]: newValue,
        ...extraProps,
      };
    });
  };

  const genderChange = (event): void => {
    setPatientData((prevState: Patient): Patient => {
      const newState = { ...prevState };
      newState.gender = event.target.value;
      return newState;
    });
  };

  return (
    <div className={clsx("bg-gray-200 rounded-lg p-4 inline-block md:flex-basis-50 flex-grow", className)}>
      <div className="flex items-center justify-center flex-wrap">
        <Card className="p-8 w-full">
          <div className="flex flex-row items-center">
            <p className="my-2">Nuevo paciente</p>
          </div>
          {country !== "México" && (
            <div className="flex flex-row items-center">
              <FormControl fullWidth>
                <RadioGroup
                  row={true}
                  value={patientData.document_type}
                  onChange={formChange}
                  defaultValue="ci"
                >
                  <FormControlLabel
                    disabled={searched}
                    value="ci"
                    control={<Radio id="document_type" />}
                    label="RUT"
                  />
                  <FormControlLabel
                    disabled={searched}
                    value="passport"
                    control={<Radio id="document_type" />}
                    label="Pasaporte"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          )}
          <div className="flex flex-row items-center">
            {(patientData.document_type === "ci" || patientData.document_type === undefined) && (
              <TextField
                disabled={searched}
                id="document_number"
                inputProps={{ maxLength: 12 }}
                onChange={formChange}
                value={patientData.document_number}
                helperText="RUT"
                fullWidth
              />
            )}
            {patientData.document_type === "passport" && (
              <TextField
                disabled={searched}
                id="document_number"
                inputProps={{ minLength: 4 }}
                onChange={formChange}
                value={patientData.document_number}
                helperText="Pasaporte"
                fullWidth
              />
            )}
          </div>
          {searched ? (
            <div className="space-y-4">
              <div className="flex flex-row items-center space-x-4">
                <TextField
                  disabled={found}
                  id="first_name"
                  onChange={formChange}
                  value={patientData.first_name}
                  helperText="Primer nombre"
                  fullWidth
                />
                <TextField
                  disabled={found}
                  id="second_name"
                  onChange={formChange}
                  value={patientData.second_name}
                  helperText="Segundo nombre"
                  fullWidth
                />
              </div>
              <div className="flex flex-row items-center space-x-4">
                <TextField
                  disabled={found}
                  id="last_name"
                  onChange={formChange}
                  value={patientData.last_name}
                  helperText="Apellido"
                  fullWidth
                />
                <TextField
                  disabled={found}
                  id="second_last_name"
                  onChange={formChange}
                  value={patientData.second_last_name}
                  helperText="Segundo apellido"
                  fullWidth
                />
              </div>
              <div className="flex flex-row items-center space-x-4">
                <TextField
                  label={emailError ? "Email con errores" : null}
                  error={emailError}
                  disabled={found}
                  id="email"
                  onChange={formChange}
                  onBlur={handleEmailBlur}
                  value={patientData.email}
                  helperText="Correo electrónico"
                  fullWidth
                />
                <TextField
                  disabled={found}
                  id="phone"
                  onChange={formChange}
                  value={patientData.phone}
                  helperText="Teléfono"
                  fullWidth
                />
              </div>
              <div className="flex flex-row items-center space-x-4">
                <FormControl fullWidth>
                  <Select
                    disabled={found}
                    onChange={genderChange}
                    value={patientData.gender}
                    fullWidth
                  >
                    <MenuItem value="male">Hombre</MenuItem>
                    <MenuItem value="female">Mujer</MenuItem>
                    <MenuItem value="other">Otro</MenuItem>
                  </Select>
                  <FormHelperText>Sexo</FormHelperText>
                </FormControl>
                <TextField
                  fullWidth
                  id="date_of_birth"
                  helperText="Fecha de nacimiento"
                  type="date"
                  onChange={formChange}
                  value={patientData.date_of_birth}
                  disabled={found}
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-row items-center">
              <Button
                className="mx-auto"
                color="primary"
                variant="contained"
                onClick={async () => fetchPatient()}
              >
                {country === "México" ? "Añadir Paciente" : "Buscar"}
              </Button>
            </div>
          )}

          {searched && !found && (
            <div className="flex flex-col items-center">
              {isFluOperative && (
                <Checkbox onChange={(event) => setAttended(event.target.checked)}>Atendido</Checkbox>
              )}
              <Button
                disabled={error || loading || !isValid}
                className="mx-auto"
                color="primary"
                variant="contained"
                onClick={async () => createPatient()}
              >
                Crear paciente
              </Button>
            </div>
          )}
          {found && (
            <p className="my-2 text-orange-500">
              Este paciente ya existe en nuestra base de datos.
              <br />
              Conéctalo a esta agenda con un click.
            </p>
          )}
          {found && (
            <div className="flex flex-col items-center">
              {isFluOperative && (
                <Checkbox onChange={() => setAttended(!attended)}>Atendido</Checkbox>
              )}
              <Button
                className="mx-auto"
                color="primary"
                variant="contained"
                onClick={async () => addPatient()}
                disabled={loading}
                >
                Conectar paciente
              </Button>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default NewPatient;
