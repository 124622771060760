import React, { useEffect, useState } from "react";

import { Button, InputLabel, MenuItem, Select, Switch } from "@material-ui/core";
import { AiOutlineClose, AiOutlineFilePdf } from "react-icons/ai";
import Swal from "sweetalert2";

import { OPEN_DOCUMENT } from "~/constants/silentAppointments";
import resultService, { deleteResult } from "~/utils/api/v1/resultService";
import { labAPI, resultAPI } from "~/utils/api/v2";
import { registerNonSilentAppointment } from "~/utils/api/v2/appointment";
import { PendingResult } from "~/utils/api/v2/results";
import { IMALAB_ID } from "~/utils/constants/labs";
import { COUNTRY_TO_CODE } from "~/utils/data/constants";
import { AppointmentPatientItem, AppointmentPatientItemPack } from "~/utils/interfaces/AppointmentPatient";
import { Laboratory } from "~/utils/interfaces/Laboratory";
import { Locale } from "~/utils/interfaces/Locale";

type ResultDisplayAndUploadProps = {
  appointmentId: string;
  patientId: string;
  country: string;
  appointmentPatientItems: AppointmentPatientItem[];
  appointmentPatientPacks: AppointmentPatientItemPack[];
};

const ResultDisplayAndUpload = ({
  appointmentId,
  patientId,
  country,
  appointmentPatientItems,
  appointmentPatientPacks,
}: ResultDisplayAndUploadProps): JSX.Element => {
  const [existingFiles, setExistingFiles] = useState<PendingResult[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [sendNotifs, setSendNotifs] = useState<boolean>(true);
  const [validPhotoUploaded, setValidPhotoUploaded] = useState<boolean>(false);
  const [allAvailableLabs, setAllAvailableLabs] = useState<Laboratory[]>([]);
  const [selectedItemsIds, setSelectedItemsIds] = useState<string[]>([]);
  const [selectedLab, setSelectedLab] = useState<string>("");

  const fetchingExistingResults = async () => {
    setLoading(true);
    const fetched = await resultAPI.list(appointmentId, patientId);
    setExistingFiles(fetched.data);
    setLoading(false);
  };

  const fetchAllAvailableLabs = async () => {
    setLoading(true);
    try {
      const labs = await labAPI.list({ country: country as Locale });
      setAllAvailableLabs(labs || []);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleChange = (event: any) => {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i] as File;
      const filename = file.name;
      const parts = filename.split(".");
      const ext = parts[parts.length - 1];

      if (ext.toLowerCase() !== "pdf") {
        Swal.fire({
          icon: "error",
          title: "El archivo debe ser un PDF",
        });
        return false;
      }

      // if file > 15MB, reject
      if (file.size > 15_000_000) {
        Swal.fire({
          icon: "error",
          title: "El archivo no debe pesar mas de 15MB",
        });
        setValidPhotoUploaded(false);
        return false;
      }
      // add file to files state
      setValidPhotoUploaded(true);
      setFiles((prevFiles) => {
        return [...prevFiles, file];
      });
    }
  };

  const uploadResultConfirmation = () => {
    Swal.fire({
      icon: "warning",
      title: "¿Quieres subir los resultados?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.value) {
        setUploading(true);
        try {
          const formData = new FormData();
          formData.append("appointment_id", appointmentId);
          formData.append("user_id", patientId);
          files.forEach((file) => {
            formData.append("files", file);
          });
          formData.append("automatic", String(false));
          formData.append("send_notifs", String(sendNotifs));
          formData.append("type", "patient");
          formData.append("lab_id", selectedLab);
          formData.append("item_ids", JSON.stringify(selectedItemsIds));

          await resultService.uploadResults(formData);

          Swal.fire({
            icon: "success",
            title: "Resultados subidos",
          });
          fetchingExistingResults();
          setFiles([]);
          setSelectedItemsIds([]);
          setSelectedLab("");
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Error al subir los resultados",
            text: JSON.stringify(err),
          });
        }

        setUploading(false);
      }
    });
  };

  const handleDeleteResult = async (id: string) => {
    Swal.fire({
      title: "¿Estás seguro que quieres eliminar este resultado?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deleteResult(id);
        if (res.status === 204) {
          Swal.fire({
            icon: "success",
            title: "Resultado eliminado",
          });
          setExistingFiles(existingFiles.filter((file) => file.id !== id));
          fetchingExistingResults();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error al eliminar el resultado",
          });
        }
      }
    });
  };

  useEffect(() => {
    fetchingExistingResults();
    fetchAllAvailableLabs();
  }, []);

  return (
    <div className="w-full mt-5 inline-block bg-whitesmoke">
      <h3>Resultados disponibles</h3>
      <div className="mt-2.5 mb-5">
        {loading ? (
          <p>Cargando resultados...</p>
        ) : existingFiles.length === 0 ? (
          <p>No hay resultados disponibles</p>
        ) : (
          <div className="gap-[1rem] flex flex-wrap">
            {existingFiles.map((existingFile: PendingResult, i) => (
              <div
                key={i}
                className="flex flex-col bg-white rounded-xl pb-3 w-[14rem] relative"
              >
                <div
                  className="absolute -top-2 -right-2 p-1 bg-red-500 rounded-full cursor-pointer hover:bg-red-600 transition-colors z-10"
                  onClick={() => handleDeleteResult(existingFile.id)}
                >
                  <AiOutlineClose className="text-white" size={16} />
                </div>
                <header className="bg-[#0D6EFD] text-white rounded-t-xl px-4 py-2 font-bold">
                  {existingFile.lab_name}
                </header>
                <a
                  target="_blank"
                  href={existingFile.file_link}
                  rel="noreferrer"
                  className="bg-white mr-2.5 p-2.5 rounded inline-flex justify-center items-center"
                  onClick={async () => {
                    await registerNonSilentAppointment({ appointmentId, reason: OPEN_DOCUMENT });
                  }}
                >
                  <AiOutlineFilePdf size={80} />
                </a>

                {existingFile.categories && !!existingFile.categories.length && (
                  <div className="px-3 max-w-[14rem]">
                    <p className="mx-0 text-xs text-gray-600">Categorías</p>
                    <ul className="pl-3 list-disc">
                      {existingFile.categories.map((category, index) => (
                        <li
                          key={index}
                          className="text-xs"
                        >
                          {category}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      {uploading ? (
        <p>Subiendo resultados...</p>
      ) : (
        <>
          <h3 style={{ marginBottom: 10 }}>Subir Resultados</h3>
          <div className="flex flex-row">
            <input
              type="file"
              accept="application/pdf"
              multiple
              onChange={handleChange}
            />
            <div>
              <InputLabel id="lab-select-label">Selecciona un laboratorio</InputLabel>
              <Select
                labelId="lab-select-label"
                id="lab-select"
                value={selectedLab}
                onChange={(e) => {
                  setSelectedLab(e.target.value as string);
                }}
                label="Lab"
                style={{ width: "100%" }}
                autoWidth
                disabled={files.length === 0}
              >
                {allAvailableLabs
                  .filter((lab) => lab.country === COUNTRY_TO_CODE[country])
                  .map((lab) => (
                    <MenuItem
                      value={lab.id}
                      key={lab.id}
                    >
                      {lab.display_name}
                    </MenuItem>
                  ))}
              </Select>
            </div>
            <div className="ml-2">
              <InputLabel id="item-select-label">Selecciona los exámenes</InputLabel>
              <Select
                labelId="item-select-label"
                id="item-select"
                value={selectedItemsIds}
                onChange={(e) => {
                  setSelectedItemsIds(e.target.value as string[]);
                }}
                label="Servicios"
                className="w-32"
                disabled={files.length === 0}
                autoWidth
                multiple
              >
                {appointmentPatientItems.map((appointmentPatientItem) => (
                  <MenuItem
                    key={appointmentPatientItem.item.id}
                    value={appointmentPatientItem.item.id}
                  >
                    {appointmentPatientItem.item.name}
                  </MenuItem>
                ))}
                {appointmentPatientPacks.map((appointmentPatientPack) =>
                  appointmentPatientPack.pack.detailed_items.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                    >
                      {item.name}
                    </MenuItem>
                  )),
                )}
              </Select>
            </div>
          </div>
          <div style={{ marginTop: 20, display: "flex", alignItems: "center" }}>
            <Switch
              checked={sendNotifs}
              onClick={() => {
                setSendNotifs(!sendNotifs);
              }}
            />
            <p>Enviar Correo/SMS</p>
          </div>
          <div style={{ marginTop: 20 }}>
            <Button
              onClick={uploadResultConfirmation}
              variant="contained"
              className="bg-examedi-blue-strong hover:bg-examedi-blue-strong-75 text-white"
              disabled={!validPhotoUploaded || !selectedLab || (!selectedItemsIds.length && selectedLab === IMALAB_ID)}
            >
              Subir Resultados
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ResultDisplayAndUpload;
